import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTimeline } from 'vuetify/lib/components/VTimeline';
import { VTimelineItem } from 'vuetify/lib/components/VTimeline';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c(VRow,[_c(VCol,{attrs:{"cols":"12","lg":"6","md":"8","offset-md":"2","offset-lg":"3"}},[_c(VRow,[_c(VCol,[_c('h2',{staticClass:"text-center text-h4"},[_vm._v(" How can I check the signal strength of my DStv decoder ")])])],1),_c(VRow,[_c(VCol,[_c(VTimeline,{staticClass:"ml-n5",attrs:{"dense":""}},[_c(VTimelineItem,{attrs:{"color":"green darken-4","icon":_vm.oneIcon}},[_c(VCard,{staticClass:"elevation-2"},[_c(VCardText,{staticClass:"text--primary"},[_vm._v(" Press "),_c('strong',[_vm._v("OK")]),_vm._v(" button on your DStv Remote ")])],1)],1),_c(VTimelineItem,{attrs:{"color":"green darken-4","icon":_vm.twoIcon}},[_c(VCard,{staticClass:"elevation-2"},[_c(VCardText,{staticClass:"text--primary"},[_vm._v(" Navigate right to "),_c('strong',[_vm._v("HELP")]),_vm._v(" and press "),_c('strong',[_vm._v("OK")]),_vm._v(" on the DStv Remote ")])],1)],1),_c(VTimelineItem,{attrs:{"color":"green darken-4","icon":_vm.threeIcon}},[_c(VCard,{staticClass:"elevation-2"},[_c(VCardText,{staticClass:"text--primary"},[_vm._v(" Scroll down to "),_c('strong',[_vm._v("Information Central")]),_vm._v(" press "),_c('strong',[_vm._v("OK")])])],1)],1),_c(VTimelineItem,{attrs:{"color":"green darken-4","icon":_vm.fourIcon}},[_c(VCard,{staticClass:"elevation-2"},[_c(VCardText,{staticClass:"text--primary"},[_vm._v(" Your DStv decoder signal strength and quality will be displayed on your TV screen. ")])],1)],1)],1)],1)],1),_c(VRow,[_c(VCol,[_c(VImg,{attrs:{"src":require("@/assets/img/faq/dstv-signal-strength.png")}})],1)],1),_c(VRow,{staticClass:"mt-8 mb-4"},[_c('back-button',{staticClass:"mx-auto"})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }