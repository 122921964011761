
















































































import { Component, Vue } from "vue-property-decorator";
import BackButton from "@/components/BackButton.vue";
import { mdiNumeric1, mdiNumeric2, mdiNumeric3, mdiNumeric4 } from "@mdi/js";
@Component({
    components: { BackButton },
})
export default class FaqHowToCheckYourDSTVSmartcardNumber extends Vue {
    oneIcon: string = mdiNumeric1;
    twoIcon: string = mdiNumeric2;
    threeIcon: string = mdiNumeric3;
    fourIcon: string = mdiNumeric4;
}
